@import './varsLanding.scss';

.language {
    margin-top: 32px;
    width: 100%;
    .country_start {
        background-color: transparent;
        width: 100%;
        min-height: 3.4vh;
        color: #fff;
        box-shadow: none;
        border-radius: 32px;
        font-size: 1.4vh;
        @include flex(center, start);

        .flag {
            @include flex(center,center);
            padding-left: 0.4vh;
            img {
                width: 34px;
                height: 34px;
                border-radius: 50%;
            }
        }

        .icon {
            margin-left: 0.4vh;
            padding: 0.8vh;
            border-radius: 50%;
            @include flex(center, center);
            background-color: #1D1D20;
        }

        .country {
            width: 100%;
            color: red;
            @include flex(center, space-between);
        }
    }

    .custom_option {
        font-size: 1.4vh;
        line-height: 1.6vh;
        padding: 1.2vh 0.4vh 1.2vh 1.2vh;
        border-radius: 32px;
        position: relative;
        cursor: pointer;
        
        color: var(--client-table-body-cell-color);
        .option {
            display: inline-flex;
            align-items: center;
            justify-content: start;
            width: auto;
            padding: 0.6vh 0.8vh;
            border-radius: 30px;
            gap: 0.8vh;

            .dot {
                border-radius: 10px;
                display: block;
                margin-right: 8px;
                height: 10px;
                width: 10px;
            }

            img {
                width: 1.6vh;
                height: 1.6vh;
                border-radius: 50%;
            }
        }

        .option_country {
            display: inline-flex;
            align-items: center;
            justify-content: start;
            width: auto;
            gap: 0.8vh;

            .dot {
                border-radius: 10px;
                display: block;
                margin-right: 8px;
                height: 10px;
                width: 10px;
            }

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }
    }

    .custom_option_selected {
        font-size: 1.4vh;
        line-height: 1.6vh;
        padding: 1.2vh 0.4vh 1.2vh 1.2vh;
        border-radius: 32px;
        position: relative;
        background-color: var(--country-select-option-hover);

        color: var(--client-table-body-cell-color);
        .option {
            display: inline-flex;
            align-items: center;
            justify-content: start;
            width: auto;
            padding: 0.6vh 0.8vh;
            border-radius: 30px;

            .dot {
                border-radius: 10px;
                display: block;
                margin-right: 8px;
                height: 10px;
                width: 10px;
            }
        }

        .option_country {
            display: inline-flex;
            align-items: center;
            justify-content: start;
            width: auto;
            gap: 0.8vh;

            .dot {
                border-radius: 10px;
                display: block;
                margin-right: 8px;
                height: 10px;
                width: 10px;
            }

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }
    }

    .custom_option:hover {
        background-color: var(--country-select-option-hover);
        color: var(--client-table-body-cell-color);
    }

    .custom_select__indicator {
        padding: 0;
    }

    .custom_icon {
        padding: 12px;
        border-radius: 50%;
        background-color: #26272B;
        @include flex(center, center);
        svg{
            path{
                stroke: #A0A0AB;
            }
            circle{
                stroke: #A0A0AB;
            }
        }
    }

    .two_select {
        width: 90%;
        @include flex(stretch, stretch);
        flex-direction: column;
        gap: 1.2vh;
    }

    .custom_option_selected {
        font-size: 1.4vh;
        line-height: 1.6vh;
        padding: 1.2vh 0.4vh 1.2vh 1.2vh;
        border-radius: 32px;
        position: relative;
        background-color: var(--country-select-option-hover);

        color: var(--client-table-body-cell-color);
        .option {
            display: inline-flex;
            align-items: center;
            justify-content: start;
            width: auto;
            padding: 0.6vh 0.8vh;
            border-radius: 30px;

            .dot {
                border-radius: 10px;
                display: block;
                margin-right: 8px;
                height: 10px;
                width: 10px;
            }
        }

        .option_country {
            display: inline-flex;
            align-items: center;
            justify-content: start;
            width: auto;
            gap: 0.8vh;

            .dot {
                border-radius: 10px;
                display: block;
                margin-right: 8px;
                height: 10px;
                width: 10px;
            }

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }
    }

    .custom_option:hover {
        background-color: var(--country-select-option-hover);
        color: var(--client-table-body-cell-color);
    }

}
.NavBar {
    @include flex(center, center);
    width: 100%;

    .NavBarContent {
        width: calc(100% - 0.8vh);
        @include flex(center, space-between);
        background: #18181B;
        padding: 4px;
        border-radius: 9999px;

        .Logo {
            width: 20%;
            padding: 3px;
            @include flex(center, start);
            gap: 10px;
            text-decoration: none;
            img{
                width: 38px;
            }
            span{
                color: var(--Global-Dark-25, #FCFCFC);
                text-align: center;
                font-size: 22px;
                font-weight: 500;
                text-decoration: none;
            }
        }
        .links{
            @include flex(center,center);
            gap: 16px;
            a{
                color: #FFF;
                font-family: $fontLanding;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                text-decoration: none;
                padding: 16px;
            }
            .tools{
                
                button{
                    @include flex(center,center);
                    gap: 12px;
                    color: #FFF;
                    font-family: $fontLanding;
                    background: none;
                    border: none;
                    padding: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;
                    line-height: 16px;
                    &.active{
                        border-radius: 9999px;
                        background: var(--Background-3, #1D1D20);
                    }
                }
                .tooltip {
                    width: calc(100% - 176px); 
                    max-width: calc(99vw - 176px);
                    padding: 56px;
                    margin: 0;
                    margin-top: 16px;
                    border-radius: 32px;
                    background: var(--Background-2, #18181B);
                    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.24);
                  }
                  .tools_tooltip{
                    width: 100%;
                    @include flex(stretch,space-between);
                    .column{
                        @include flex(start,start);
                        flex-direction: column;
                        gap: 24px;
                        flex: 1;
                        .column_title{
                            color: var(--Global-Dark-500, #70707B);
                            font-family: $fontLanding;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px; /* 114.286% */
                        }
                        .links_tooltip{
                            @include flex(start,start);
                            flex-direction: column;
                            gap: 16px;
                            a{
                                color: var(--Neutrals-White, #FFF);
                                font-family: $fontLanding;
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 24px;
                                padding: 0;
                            }
                        }
                    }
                    .column_center{
                        @include flex(start,start);
                        flex-direction: column;
                        gap: 24px;
                        flex: 2;
                        .column_title{
                            color: var(--Global-Dark-500, #70707B);
                            font-family: $fontLanding;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px; /* 114.286% */
                        }
                        .links_tooltip{
                            @include flex(start,start);
                            flex-direction: column;
                            gap: 16px;
                            a{
                                color: var(--Neutrals-White, #FFF);
                                font-family: $fontLanding;
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 24px;
                                padding: 0;
                            }
                        }
                    }
                    .contacts{
                        padding-left: 32px;
                        border-left: 1px solid #26272B;
                        @include flex(start,start);
                        flex-direction: column;
                        gap: 24px;
                        flex: 0.5;
                        .column_title{
                            color: var(--Global-Dark-500, #70707B);
                            font-family: $fontLanding;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px; /* 114.286% */
                        }
                        .contacts_content{
                            height: 100%;
                            @include flex(start,space-between);
                            flex-direction: column;
                            .address{
                                color: var(--Neutrals-White, #FFF);
                                font-family: $fontLanding;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 24px; /* 171.429% */
                            }
                            .contact_data{
                                @include flex(start,start);
                                flex-direction: column;
                                gap: 8px;
                                p{
                                    margin: 0;
                                    color: var(--Neutrals-White, #FFF);
                                    font-family: $fontLanding;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                  }
            }
        }
        .Navigation {
            @include flex(center, end);
            gap: 8px;

            a {
                color: #fff;
                font-size: 18px;
                text-decoration: none;
                transition: all 0.3s ease;
            }

            a:hover {
                color: #52A5E6;
            }

            .Login {
                background: #18181B;
                color: #fcfcfc;
                cursor: pointer;
                border-radius: 9999px;
                padding: 14px 24px;
                font-size: 1.4vh;
                font-family: $fontLanding;
            }

            .Login:hover {
                transition: all 0.3s ease;
                background: #26272B;
                color: #fcfcfc;
            }

            .Cabinet {
                border: 2px solid transparent;
                border-radius: 1000px;
                background: 
                  linear-gradient(to right, #18181B, #18181B), 
                  linear-gradient(to right, #A855F78F , #FFFFFF8F); 
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                @include flex(center,center);
                width: 100px;
                padding: 12px;
                font-size: 1.4vh;
                font-family: $fontLanding;
            }
            .Cabinet:hover {
                transition: all 0.3s ease; /* Плавный переход для ховера */
                background: #26272B;
                color: #fcfcfc;
            }
            .lang{
                padding: 8px;
                @include flex(center,center);
                border-radius: 9999px;
                background-color: #1D1D20;
                cursor: pointer;
            }
            .lang:hover{
                transition: all 0.3s ease; /* Плавный переход для ховера */
                background: #26272B;
            }
            
        }
        .mobileButton{
            display: none;
        }
    }
}
@media (max-width: 950px) {
    .NavBar{
        padding: 16px;
        width: calc(100% - 32px);
        .NavBarContent {
            width: 100%;
            background: none;
            .Logo{
                width: 70%;
                text-decoration: none;
                img{
                    width: 40px;
                }
                span{
                    color: var(--Global-Dark-25, #FCFCFC);
                    text-align: center;
                    font-size: 22px;
                    font-weight: 500;
                    text-decoration: none; 
                }
            }
            .links{
                display: none;
            }
            .Navigation{
                display: none;
            }
            .mobileButton{
                @include flex(center,center);
                padding: 12px;
                background-color: var(--Background-2, #18181B);
                border-radius: 9999px;
                cursor: pointer;
                z-index: 10000;
                }
        }
    }
}

.menu {
margin: 100px 16px 16px;
display: flex;
padding: 32px;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
gap: 32px;
border-radius: 32px;
background: var(--Background-2, #18181B);
box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.24);
width: calc(100% - 96px);
.login_register{
    width: 100%;
    @include flex(stretch,space-between);
    gap: 8px;
    .login{
        flex: 1;
        border-radius: 9999px;
        background: var(--Background-3, #1D1D20);
        color: var(--Nav_Button-Font_Active, #FCFCFC);
        font-size: 14px;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
        text-decoration: none;
        @include flex(center,center);
        padding: 12px 24px;
    }
    .register{
        flex: 1;
        border: 2px solid transparent;
        border-radius: 1000px;
        background: 
          linear-gradient(to right, #18181B, #18181B), 
          linear-gradient(to right, #A855F78F , #FFFFFF8F); 
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        @include flex(center,center);
        padding: 12px 24px;
        color: var(--Nav_Button-Font_Active, #FCFCFC);
        font-size: 14px;
        font-weight: 500;
        line-height: 16px; 
        text-decoration: none;

    }
}
}

.menu__container {
display: flex;
flex-direction: column;
height: 176px;
justify-content: space-between;
width: 100%;
}

.menu__item {
display: flex;
align-items: center;

    span {
        color: var(--Font-Sub-Font, #51525C);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-right: 20px;
    }

    p {
        color: var(--Neutrals-White, #FFF);
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 0;
display: flex;
justify-content: space-between;
    }
}

.menu__title {
    color: var(--Global-Dark-500, #70707B);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.box {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.title {
    display: flex;
    align-items: center;
}

.icon {
    transform: rotate(180deg);
    margin-right: 16px;
}

.menu__tools {
    margin: 100px 16px 16px;
display: flex;
padding: 32px;
flex-direction: column;
align-items: flex-start;
gap: 12px;
border-radius: 32px;
background: var(--Background-2, #18181B);
box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.24);
width: calc(100% - 96px);


p {
    color: var(--Neutrals-White, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}

h1 {
    color: var(--Neutrals-White, #FFF);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

span {
    color: var(--Global-Dark-500, #70707B);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 12px;
    margin-top: 48px;
}
}

.first_title {
    margin-top: 32px !important;
}